import { Swiper } from '../../types/swiper';

type TabData = {
  content: string;
  title: string;
};

export function initPhoneSlider(): void {
  if (!window.Swiper) {
    return;
  }

  // Elementor provides us with Swiper V5.3
  // See docs here: https://web.archive.org/web/20200306021734/https://swiperjs.com/api/
  // Version 6.x.x of Swiper comes with Typescript types and better events.
  new window.Swiper('.phone-slider', {
    pagination: {
      el: '.tab-container',
      type: 'custom',
      renderCustom: renderCustomPagination,
    },
    a11y: {
      enabled: true,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  });
}

function renderCustomPagination(swiperInstance: Swiper, current: number, _total: number): string {
  window.embark = window.embark || {};
  window.embark.SwiperInstance = swiperInstance;
  const tabContentJSON = JSON.parse(document.querySelector('script.phone-slider-data')?.innerHTML || '[]');
  const tabs: string[] = [];
  const tabsContentWrapper: string[] = [];

  tabContentJSON.forEach((tab: TabData, index: number) => {
    const isActiveTab = index + 1 === current;
    const activeClass = isActiveTab ? 'active' : '';
    const activeAttribute = isActiveTab ? 'aria-selected="true"' : '';
    tabs.push(
      `<li class="tab-title ${activeClass}">
        <button ${activeAttribute} onclick="window.embark.SwiperInstance.slideTo(${index})">
            ${tab.title}
        </button>
      </li>`
    );
    tabsContentWrapper.push(
      `<div class="tab-content-wrapper ${activeClass}">
        ${tab.content}
      </div>`
    );
  });

  const tabSectionID = Date.now().toString(36);

  return `
        <ul class="tab-controls" id="${tabSectionID}">
            ${tabs.join('')}
        </ul>
        <div class="tab-content" data-tabs-content="${tabSectionID}">
            ${tabsContentWrapper.join('')}
        </div>`;
}
