import { DOMRouteHandler } from '../util/Router';

export class PageTemplateTempCustomPage extends DOMRouteHandler {
  static finalize(): void {
    pauseModalVideosOnModalClose();
  }
}

function pauseModalVideosOnModalClose(): void {
  const videoModalContainers: HTMLElement[] = Array.prototype.slice.call(
    document.querySelectorAll('.reveal.sectionVideoGrid-videoCellReveal')
  );

  videoModalContainers.map((container) => {
    jQuery(document).on('closed.zf.reveal', `#${container.id}[data-reveal]`, (event) => {
      const embeddedVideos: HTMLIFrameElement[] = Array.prototype.slice.call(
        event.target.getElementsByTagName('iframe')
      );
      embeddedVideos.map((iframe) => {
        if (iframe.contentWindow) {
          iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        }
      });
    });
  });
}
