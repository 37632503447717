import { DOMRouteHandler } from '../util/Router';

export class PageTemplateTempBreedReveal extends DOMRouteHandler {
  static init(): void {
    import('../modules/breed-reveal')
      .then(({ initBreedReveal }) => {
        initBreedReveal();
      })
      .catch((error) => {
        //eslint-disable-next-line no-console
        console.log(error, 'Error when loading breed-reveal page');
      });
  }
}
