import { setFeatureCardClickHandlers } from './image-helpers';

export function setDeviceSpecificClickHandlers() {
  const winWidth = window.innerWidth;
  if (winWidth > 767) {
    setFeatureCardClickHandlers('modal-trigger');
  } else {
    setFeatureCardClickHandlers('modal-trigger-small');
  }
}
