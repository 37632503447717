import { EV_API_URL } from '../util/config';
import { assetUrl } from '../util/helpers';
import { maybeAddDisclaimer } from './ConditionDisclaimers';

/**
 * Render the list of diseases
 *
 * @param {Array.<{
 *          isRecommendedByOFA: Boolean,
 *          condition: {
 *              disorder_name: String,
 *              lay_term: String,
 *              gene: String,
 *              moi: String,
 *              consumer_brief_description_required: String
 *          }
 *        }>
 *      } diseases
 * @return void
 */
function render(diseases) {
  /**
   * Create the required nodes for each disease and append them to DOM
   * attach event listeners where needed.
   */
  diseases.forEach((disease) => {
    const { condition } = disease;
    const buttonLabel = {
      closed: 'Show more…',
      opened: 'Show less…',
    };
    const heading = jQuery('<h5 class="medium-8 small-11"></h5>');
    const OFAIcon = jQuery('<div class="medium-1 small-1"></div>');
    const button = jQuery(
      `<div class="medium-3 small-8">
            <button type="button"
                    class="button-medium show-more-button
                           button-primary-light">${buttonLabel.closed}</button>
        </div>`
    );
    const description = jQuery('<div class="medium-10 disorder-description" tabindex="-1"></div>');
    const grid = jQuery('<div class="medium-10 grid-x disorder-container"></div>');

    heading.text(condition.disorder_name);
    grid.append(heading);
    if (disease.isRecommendedByOFA) {
      OFAIcon.append(jQuery(`<img class="medium-1 ofa-logo" src="${assetUrl('/img/logo/ofa-logo.png')}" alt="OFA">`));
    }

    grid.append(OFAIcon);
    button.labelToggle = function () {
      const $button = this.find('button');
      if ($button.text() === buttonLabel.closed) {
        $button.text(buttonLabel.opened);
      } else {
        $button.text(buttonLabel.closed);
      }
    };
    button.find('button').click((e) => {
      e.preventDefault();
      description.slideToggle();
      description.focus();
      button.labelToggle();
    });
    grid.append(button);
    description.html(`
        <small>${condition.lay_term} (${condition.category})</small>
        <div>
            <h5>Gene: ${condition.gene}</h5>
            <h6>Inheritance type: ${condition.moi} </h6>
        </div>
        <div>
            <p style="color: #69727b">${condition.consumer_brief_description_required}</p>
        </div>
     `);

    if (window.embarkConditionDisclaimerStrings) {
      maybeAddDisclaimer(condition, description, embarkConditionDisclaimerStrings, 'conditionShortcode');
    }

    grid.append(description);
    jQuery('.breed-health-list').append(grid);
  });
}

export function shortcodeBreedHealth() {
  const shortCodeMarkup = document.getElementsByClassName('breed-health-list');

  for (const element of shortCodeMarkup) {
    const breed = element.getAttribute('data-breed');

    if (!breed) {
      return; // No breed attribute was set on the shortcode html.
    }

    jQuery
      .getJSON(`${EV_API_URL}/bcd/display/${encodeURIComponent(breed)}`, (diseases) => render(diseases))
      .catch((_error) => {
        // TODO: Handle this. Maybe notify Sentry once a client side helper is created.
      });
  }
}
