import { EventPropertiesType, pushGTMCustomEvent } from '../../modules/pushGTMCustomEvent';

import { notifyHandledException } from '../../util/error-handling';

export async function healthConditionsSearchInit(): Promise<void> {
  if (
    document.querySelector('.breed-search.breed-health-search') ||
    document.querySelector('.breed-search.breed-health-search-custom-ui')
  ) {
    import('../../modules/BreedHealthConditions')
      .then(
        async ({
          BreedHealthConditions,
          moveAutocompleteWidgetResultsUnderInputSection,
          replaceAutocompleteResultsMarkup,
        }) => {
          if (document.querySelector('.breed-search.breed-health-search')) {
            await BreedHealthConditions.init();
          }

          if (document.querySelector('.breed-search.breed-health-search-custom-ui')) {
            const breedSearchAutocompleteOptions = {
              select(_event: JQuery.UIEventBase, ui: JQueryUI.AutocompleteUIParams) {
                const { item } = ui;
                const searchType = 'Breed Direct Search';
                const eventProperties: EventPropertiesType = {
                  eventCategory: searchType,
                  eventAction: item.label,
                  eventURL: window.location.href,
                };
                if (item.id) {
                  pushGTMCustomEvent(searchType, eventProperties);
                  window.location.href = `/?p=${item.id}`;
                  return false;
                }

                eventProperties.eventAction += ' - no results';
                pushGTMCustomEvent(searchType, eventProperties);
                return true;
              },
              create(event: JQuery.UIEventBase) {
                const instance = jQuery(event.target).autocomplete('instance');
                moveAutocompleteWidgetResultsUnderInputSection(instance);
                replaceAutocompleteResultsMarkup(instance);
              },
            };

            const useCachedBreedsList = true;
            await BreedHealthConditions.init(breedSearchAutocompleteOptions, useCachedBreedsList);
          }
        }
      )
      .catch((e) => notifyHandledException(e));
  }

  if (document.querySelector('.health-search.breed-health-search')) {
    try {
      const { HealthConditions } = await import('../../modules/HealthConditions');
      await HealthConditions.init();
    } catch (e) {
      notifyHandledException(new Error('Failed to load chunk ../../modules/HealthConditions'));
    }
  }
}
