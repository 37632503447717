function indicateActiveElements(container: HTMLElement): void {
  const children = Array.from(container.children);
  const navItems = [...children].map((i) => {
    const anchor = `#${i.getAttribute('id')}`;
    return document.querySelector(`a[href='${anchor}']`);
  });

  const activate = (target: HTMLElement): void => {
    navItems.forEach((navItem) => {
      if (navItem instanceof HTMLElement) {
        navItem.classList.remove('active');
      }
    });

    const selector = target.getAttribute('id');
    const anchor = document.querySelector(`a[href="#${selector}"]`);
    if (anchor instanceof HTMLElement) {
      anchor.classList.add('active');
    }
  };

  const intersectionObserver = new IntersectionObserver(
    function (entries) {
      entries.forEach((entry) => {
        if (entry.target instanceof HTMLElement && entry.isIntersecting) {
          activate(entry.target);
        }
      });
    },
    {
      threshold: 0.6,
    }
  );

  children.forEach((child) => {
    if (child instanceof HTMLElement) {
      intersectionObserver.observe(child);
    }
  });
}

function addNavigation(navigationContainer: HTMLElement): void {
  const navigationAnchors = navigationContainer.querySelectorAll<HTMLAnchorElement>('a[href^="#"]');
  const container = document.querySelector(navigationContainer.dataset.controls as string);
  if (!(container instanceof HTMLElement)) {
    return;
  }

  indicateActiveElements(container);

  navigationAnchors.forEach((anchor) => {
    const targetSelector = anchor.getAttribute('href');
    const targetElement = container.querySelector<HTMLElement>(targetSelector as string);
    anchor.addEventListener('click', (e) => {
      e.preventDefault();
      const left = targetElement?.offsetLeft;

      container.scrollTo({
        behavior: 'smooth',
        left,
      });
    });
  });
}

export function initSnapScrollNavigation(navigationContainer: NodeListOf<HTMLElement>): void {
  navigationContainer.forEach(addNavigation);
}
