export function retryUntil(condition: boolean, callback: () => void, currentRetryCount = 0): void {
  const MAX_ATTEMPTS = 20;

  if (currentRetryCount > MAX_ATTEMPTS) {
    return;
  }

  if (condition) {
    callback();
    return;
  }

  setTimeout(() => retryUntil(condition, callback, ++currentRetryCount), 300);
}
