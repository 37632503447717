import { DOMRouteHandler } from '../util/Router';

export class PageTemplateTempShelters extends DOMRouteHandler {
  static init(): void {
    const successMessage = document.querySelector('.shelter-form-success');
    document.addEventListener('wpcf7submit', ((event: CustomEvent) => {
      if (event.detail.apiResponse.status === 'mail_sent' && event.target instanceof HTMLElement) {
        event.target.style.display = 'none';
        if (successMessage instanceof HTMLElement) {
          successMessage.style.display = 'block';
        }
      }
    }) as EventListener);
  }
}
