declare global {
  interface Window {
    zE: (object: string, command: string) => void;
  }
}

export function makeLiveChatUpdates(): void {
  const usPhoneNumber = document.querySelector('#footer .phone-number--us a');

  if (usPhoneNumber instanceof HTMLAnchorElement) {
    usPhoneNumber.href = 'https://help.embarkvet.com/hc/en-us?liveChat=open';
    usPhoneNumber.innerHTML = 'Chat with us';
  }

  const callUs = document.querySelector('.support-call-us');

  if (callUs) {
    callUs.innerHTML = `
            <div class="support-chat-with-us" style="margin-bottom: 16px;">
                <a href="https://help.embarkvet.com/hc/en-us?liveChat=open">Chat with us</a>
            <div>
        `;
  }

  const supportForQuestions = document.querySelector('.support-for-questions');
  if (supportForQuestions) {
    supportForQuestions.remove();
  }
}

export function addLiveChatWidgetAndLinks(): void {
  const src = 'https://static.zdassets.com/ekr/snippet.js?key=b6950fc9-2eef-448b-8269-e596df88901b';
  const s = document.createElement('script');
  s.src = src;
  s.id = 'ze-snippet';
  s.async = true;
  s.onload = function () {
    if (window.location.search.includes('liveChat=open')) {
      setTimeout(function () {
        if (typeof window.zE === 'function') {
          window.zE('messenger', 'open');
        }
      }, 100);
    }
  };
  document.body.appendChild(s);

  const helpCenterPhoneNumbers = document.querySelectorAll('.help-center-phone-number,.in-article-phone-number');
  helpCenterPhoneNumbers.forEach(function (helpCenterPhoneNumber) {
    helpCenterPhoneNumber.innerHTML = "<a href=\"javascript:zE('messenger', 'open')\">Chat with us</a>";
  });
}
