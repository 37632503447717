import debounce from 'lodash/debounce';

const filtersForm = document.getElementById('databaseFilters');

function isSearchParamAnArray(param: string): boolean {
  return param.match(/\[.*\]/) !== null;
}

export function updateFiltersState(url: URL, data: FormData): void {
  if (data.has('q') && data.get('q') !== '') {
    resetFilters();
  }

  const allParams = Array.from(url.searchParams);
  allParams.forEach(([key]) => {
    if (key.startsWith('filter')) {
      url.searchParams.delete(key);
    }
  });

  data.forEach((value, name) => {
    if (!value) {
      url.searchParams.delete(name);
    }

    if (isSearchParamAnArray(name)) {
      url.searchParams.append(name, value.toString());
      return;
    }

    url.searchParams.set(name, value.toString());
  });

  history.pushState(null, '', url.href);
  window.dispatchEvent(new CustomEvent('filters:updated'));
  const hiddenEls = document.querySelectorAll('.search form input[type=hidden]');
  hiddenEls.forEach((el) => el.remove());
}

export function resetSearch(): void {
  const url = new URL(window.location.href);
  url.searchParams.delete('q');
  history.pushState(null, '', url.href);
  const searchInput = document.querySelector('.database-hero__search input[type=search]');
  if (searchInput instanceof HTMLInputElement) {
    searchInput.value = '';
  }

  document.querySelectorAll('.database-hero__search input[type=hidden]').forEach((el) => {
    el.remove();
  });
}

export function resetFilters(): void {
  const url = new URL(window.location.href);

  const allParams = Array.from(url.searchParams);
  allParams.forEach(([key]) => {
    if (key.startsWith('filter')) {
      url.searchParams.delete(key);
    }
  });

  history.pushState(null, '', url.href);
  const filterForms = document.querySelectorAll('#databaseFilters');
  filterForms.forEach((form) => {
    if (!(form instanceof HTMLFormElement)) {
      return;
    }

    form.querySelectorAll('input').forEach((el) => {
      if (!(el instanceof HTMLInputElement)) {
        return;
      }
      el.removeAttribute('checked');
    });

    form.reset();
  });
  window.dispatchEvent(new CustomEvent('filters:updated'));
  const filters = document.querySelector('.database-page__filters');
  filters?.classList.remove('database-page__filters--active');
}

export function toggleFiltersModal(): void {
  const filters = document.querySelector('.database-page__filters');
  filters?.classList.toggle('database-page__filters--active');
  const breedFilters = document.querySelector('.database-breed .database-page__filters');
  breedFilters?.classList.toggle('has-mobile-styles');

  const alphaFilter = document.querySelector('.database-page__filters .accordion-item--starts_with .accordion-title');

  if (!alphaFilter || !(alphaFilter instanceof HTMLAnchorElement)) {
    return;
  }

  const filterIsOpen = alphaFilter.getAttribute('aria-expanded');

  if (filterIsOpen !== 'true') {
    alphaFilter?.click();
  }
}

export function initTraitPageFilters(): void {
  if (!(filtersForm instanceof HTMLFormElement)) {
    return;
  }

  filtersForm.addEventListener(
    'change',
    debounce(function () {
      const data = new FormData(filtersForm);
      const url = new URL(window.location.href);
      updateFiltersState(url, data);
    }, 300)
  );

  const letters = filtersForm.querySelectorAll('.starts_with-filter input');
  letters.forEach((letter) => {
    letter.addEventListener('keydown', function (event) {
      const target = event.target;
      if (target instanceof HTMLInputElement && event instanceof KeyboardEvent && event.key === 'Enter') {
        event.preventDefault();
      }
    });
  });

  document.querySelector('.filters-toggle')?.addEventListener('click', toggleFiltersModal);
  document.querySelectorAll('.close-drawer').forEach((item) => {
    item.addEventListener('click', toggleFiltersModal);
  });
}

export function setActiveLettersFilterElements(): void {
  const letterFilterInputs = document.querySelectorAll('.starts_with-filter input');
  letterFilterInputs.forEach((el) => {
    if (!(el instanceof HTMLInputElement)) {
      return;
    }

    const containerEl = el.closest('li');

    if (el.checked) {
      containerEl?.classList.add('active');
    } else {
      containerEl?.classList.remove('active');
    }
  });
}
