import debounce from 'lodash/debounce';
import { updateFiltersState } from './filters';

const searchForm = document.querySelector('.search form');

export const performSearch = (trait: Element): boolean => {
  if (!(trait instanceof HTMLElement)) {
    return false;
  }

  const params = new URLSearchParams(window.location.search);
  const query = params.get('q');
  if (!query) {
    return true;
  }

  return trait.innerText.toLowerCase().indexOf(query.toLowerCase()) >= 0;
};

export function initTraitsPageSearch(): void {
  if (!(searchForm instanceof HTMLFormElement)) {
    return;
  }

  searchForm.addEventListener(
    'input',
    debounce(function () {
      const data = new FormData(searchForm);
      const url = new URL(window.location.href);
      updateFiltersState(url, data);
    }, 300)
  );
}
