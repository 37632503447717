import { DOMRouteHandler } from '../util/Router';

export class SingleBreeds extends DOMRouteHandler {
  static init(): void {
    const $ = jQuery;
    // breed-page
    $('[data-action=showmore]').click(function () {
      $(this).closest('li').find('.hidden-content').addClass('shown-content').removeClass('hidden-content');
      $(this).closest('li').find('.show-less').show();
      $(this).hide();
    });

    $('[data-action=showless]').click(function () {
      $(this).closest('li').find('.shown-content').addClass('hidden-content').removeClass('shown-content');
      $(this).closest('li').find('.show-more').show();
      $(this).hide();
    });
  }
}
