import { Swiper, SwiperOptions } from '../types/swiper';

export function initSwiper(selector = '.swiper-container', options: SwiperOptions = {}): Swiper | null {
  if (!window.Swiper) {
    return null;
  }

  const defaults: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 10,
    breakpoints: {
      1024: {
        spaceBetween: 40,
      },
      1441: {
        spaceBetween: 85,
      },
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    loop: true,
    loopedSlides: 7,
    a11y: {
      enabled: true,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  };

  const instance: Swiper = new window.Swiper(selector, Object.assign({}, defaults, options));

  instance.on('sliderMove', () => {
    instance.$el.addClass('swiper-dragging');
  });

  instance.on('slideChange', () => {
    instance.$el.removeClass('swiper-dragging');
  });

  return instance;
}
