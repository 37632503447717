export function initLegacyLazyloadImageHandling(): void {
  const lazyImageElements = [].slice.call(document.querySelectorAll('.lazyload[data-bg], .lazyload[data-src]'));

  if ('IntersectionObserver' in window) {
    const lazyImageObserver = new IntersectionObserver((entries) => {
      entries.forEach(function (entry) {
        if (entry.isIntersecting && entry.target) {
          if (entry.target instanceof HTMLImageElement) {
            maybeUpdateImageSrc(<HTMLImageElement>entry.target);
          } else {
            maybeUpdateBackgroundImageStyle(<HTMLElement>entry.target);
          }
          lazyImageObserver.unobserve(entry.target);
        }
      });
    });

    lazyImageElements.forEach(function (lazyBackground) {
      lazyImageObserver.observe(lazyBackground);
    });
  }
}

const verifyURLAppearsValid = (urlString: string): boolean => {
  try {
    new URL(urlString.trim());
  } catch (error) {
    return false;
  }
  return true;
};

const maybeUpdateBackgroundImageStyle = (targetElement: HTMLElement): void => {
  const bgImage = targetElement?.dataset?.bg || '';
  if (bgImage && verifyURLAppearsValid(bgImage)) {
    targetElement.style.backgroundImage = `url(${bgImage})`;
  }
};

// This shouldn't conflict with cloudinary lazyload as it always uses data-srcset.
const maybeUpdateImageSrc = (targetElement: HTMLImageElement): void => {
  const imageSource = targetElement?.dataset?.src || '';
  if (imageSource && verifyURLAppearsValid(imageSource)) {
    targetElement.src = imageSource;
  }
};
