export function setupScrollReveal() {
  const $ = jQuery;

  if (document.querySelector('.scroll-reveal')) {
    import('scrollreveal')
      .then(({ default: ScrollRevealJS }) => {
        const ScrollReveal = ScrollRevealJS();

        ScrollReveal.reveal('.scroll-reveal', {
          viewFactor: 0.2,
          origin: 'top',
        });
        for (let i = 1; i < 10; i++) {
          if ($('.sr-' + i).length) {
            ScrollReveal.reveal('.sr-' + i, { delay: i * 200 });
          }
        }

        ScrollReveal.reveal('.sr-left', { origin: 'left' });
        ScrollReveal.reveal('.sr-right', { origin: 'right' });
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.warn('failed to load ScrollRevealJS', e);
      });
  }
}
