import { DOMRouteHandler } from '../util/Router';

export class PageTemplateTempAncestry extends DOMRouteHandler {
  static init(): void {
    import('../modules/dog-carousel')
      .then(({ breedAncestryTabs, dogCarouselSubscribeToScroll }) => {
        // make the dog carousel nav sticky
        dogCarouselSubscribeToScroll();
        // add click handlers to switch between dog carousel tabs
        breedAncestryTabs();
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.warn(e);
      });
  }
}
