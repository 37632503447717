export function setFeatureCardClickHandlers(triggerClass) {
  if (!document.body.className.includes('page-template-temp-homepageV2')) {
    /*
     * Only run this if on homepageV2 template.
     *
     * Ideally we would be emitting events to hook onto for each page. But this
     * will do for now.
     */
    return;
  }

  const modalTriggers = document.getElementsByClassName(triggerClass);

  for (const element of modalTriggers) {
    element.addEventListener('click', () => {
      const modalToTrigger = element.getAttribute('data-modal');
      const slideBulletToClick = element.getAttribute('data-slide');
      jQuery(document.getElementById(modalToTrigger)).foundation('open');
      jQuery(document.getElementById(slideBulletToClick)).click();
    });
  }
}
