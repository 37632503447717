import { DOMRouteHandler } from '../util/Router';

export class PageTemplateTempTraitsList extends DOMRouteHandler {
  static init(): void {
    PageTemplateTempTraitsList.moveFocusToActiveTraitsCategory();
  }

  static moveFocusToActiveTraitsCategory(): void {
    jQuery('.tabs').on('click.zf.magellan', 'a[href^="#"]', (e) => {
      const currentTarget = e.currentTarget;

      if (!(currentTarget instanceof HTMLAnchorElement)) {
        return;
      }

      const scrollTargetSelector = currentTarget.getAttribute('href');

      if (!scrollTargetSelector?.startsWith('#')) {
        return;
      }

      const scrollTarget = document.querySelector(scrollTargetSelector);

      if (scrollTarget instanceof HTMLElement) {
        scrollTarget.focus();
      }
    });
  }
}
