import { DEFAULT_PER_PAGE, renderPaginationUI } from './pagination';
import { initTraitPageFilters, resetFilters, resetSearch, setActiveLettersFilterElements } from './filters';

import Fuse from 'fuse.js';
import { initTraitDescriptionAccordion } from './accordion';
import { initTraitsPageSearch } from './search';
import uniq from 'lodash/uniq';

const traitItems = document.querySelectorAll('.database-item');
const noResultsMessage = document.querySelector('.no-results-message');
const paginationContainer = document.getElementById('databasePagination');
const filterDetails = document.querySelectorAll('.filter-details');
const searchDetails = document.querySelectorAll('.search-details');

function filterResults(): void {
  const url = window.location.search;
  const params = new URLSearchParams(url);

  traitItems.forEach((item) => {
    if (item instanceof HTMLElement) {
      item.style.display = 'none';
    }
  });

  let results: Array<HTMLElement | Element> = [];
  const filterParams = [...params.keys()].filter((k) => k.startsWith('filter['));

  params.forEach((value, name) => {
    const labelMatches = name.match(/filter\[([^\]]+)\]\[\]/);
    let items;
    if (!filterParams.includes(name)) {
      return;
    }

    if (labelMatches) {
      const label = labelMatches[1];
      items = document.querySelectorAll(`[data-${label}='${value}']`);
    } else {
      items = document.querySelectorAll('.database-item');
    }

    results = [...results, ...Array.from(items)];
  });

  if (!filterParams.length) {
    results = Array.from(document.querySelectorAll('.database-item'));
  }

  if (filterParams.length > 1) {
    results = uniq(results).filter((result) => {
      if (!(result instanceof HTMLElement)) {
        return false;
      }

      const filterEvaluations = [];
      for (const param of uniq(filterParams)) {
        const filters = params.getAll(param);
        const matches = param.match(/filter\[([^\]]+)\]\[\]/);
        if (!matches) {
          return false;
        }

        const label = matches[1];
        const includes = filters.includes(result.getAttribute(`data-${label}`) || '');
        filterEvaluations.push(includes);
      }

      return !filterEvaluations.includes(false);
    });
  }

  const fuse = new Fuse(results, {
    keys: [
      'category',
      'disorder_name',
      'subdisorder_name',
      'description',
      'geneLabel',
      'label',
      'short_description',
      'akc_group',
      'ukc_group',
      'display_name',
      'size_level',
      'starts_with',
      'seen_in_breeds',
    ],
    getFn(item, path) {
      if (!(item instanceof HTMLElement)) {
        return;
      }

      try {
        const json = JSON.parse(item.dataset.item || '{}');
        const index = Array.isArray(path) ? path[0] : path;

        if (!json.hasOwnProperty(index)) {
          return;
        }

        return json[index];
      } catch (e) {
        return item.innerText.toLowerCase();
      }
    },
    ignoreLocation: true,
    shouldSort: true,
    includeScore: true,
    findAllMatches: true,
    includeMatches: true,
    threshold: 0.2,
  });

  const query = params.get('q');
  let filteredItems;
  if (query) {
    filteredItems = fuse.search(query.trim(), {
      limit: 100,
    });
  } else {
    filteredItems = results.map((item) => ({ item }));
  }

  setupPagination((params.get('current_page') || 1) as number, filteredItems);

  const trimmedQuery = query && query.length > 20 ? query.substring(0, 20) + '...' : query;
  searchDetails.forEach((searchDetail) => {
    if (trimmedQuery && searchDetail instanceof HTMLElement) {
      const searchQuery = document.querySelectorAll('[data-search-term]');
      searchQuery.forEach((item) => {
        item.setAttribute('title', query || '');
        item.innerHTML = trimmedQuery;
      });
      searchDetail.style.display = 'flex';
    }

    if (!query && searchDetail instanceof HTMLElement) {
      searchDetail.style.display = 'none';
    }
  });

  // update numbers on page
  filterDetails.forEach((filterDetail) => {
    if (filterDetail instanceof HTMLElement) {
      const numberOfFilters = filterDetail.querySelectorAll('[data-number-of-filters]');
      const numberOfItems = document.querySelectorAll('[data-number-of-results]');

      let filtersCount = 0;
      params.forEach((value, key) => {
        if (key.startsWith('filter') && value) {
          filtersCount += 1;
        }
      });

      const resultsCount: number = filteredItems.length;

      numberOfFilters.forEach((item) => {
        item.innerHTML = `${filtersCount}`;
      });
      numberOfItems.forEach((item) => {
        item.innerHTML = `${resultsCount}`;
      });

      filterDetail.style.display = filtersCount > 0 ? 'flex' : 'none';
    }
  });

  filteredItems.forEach(({ item }) => {
    if (item instanceof HTMLElement) {
      item.style.display = 'flex';
    }
  });

  paginate(
    new CustomEvent('onload:paginate'),
    window.parseInt(params.get('current_page') || '1'),
    undefined,
    Array.from(filteredItems)
  );

  if (noResultsMessage instanceof HTMLElement) {
    noResultsMessage.style.display = !filteredItems.length ? 'flex' : 'none';
  }
}

const paginate = (e: Event, page: number, perPage?: number, nodes?: any[]): void => {
  if (!paginationContainer) {
    return;
  }

  const url = new URL(location.href);
  url.searchParams.set('current_page', page.toString());
  history.pushState(null, '', url.toString());
  const items = nodes || document.querySelectorAll('.database-item');
  e.preventDefault();

  items.forEach((node) => {
    const item = node.item || node;
    item.style.display = 'none';
  });
  const itemsPerPage = perPage || DEFAULT_PER_PAGE;
  const totalPages = Math.ceil(items.length / itemsPerPage);
  if (page > totalPages) {
    page = totalPages;
  }

  const thisPageItems = Array.from(items).splice((page - 1) * itemsPerPage, itemsPerPage);

  thisPageItems.forEach((node) => {
    const item = node.item || node;
    item.style.display = 'flex';
  });
  setupPagination(page, items as any[]);
};
export function setupPagination(page: number, nodes?: Array<any>): void {
  renderPaginationUI(
    paginationContainer,
    (e, page, perPage) => paginate(e, page, perPage, nodes),
    page,
    DEFAULT_PER_PAGE,
    nodes ? nodes.length : document.querySelectorAll('.database-item').length
  );
}

export const initDatabasePageTemplate = (): void => {
  initTraitPageFilters();
  initTraitsPageSearch();
  initTraitDescriptionAccordion();
  window.addEventListener('filters:updated', filterResults);
  window.addEventListener('filters:updated', setActiveLettersFilterElements);
  document.querySelectorAll('.reset-filters').forEach((el) => {
    el.addEventListener('click', () => {
      resetSearch();
      resetFilters();
    });
  });
  window.dispatchEvent(new CustomEvent('filters:updated'));
};
