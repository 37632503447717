import { initConclusionBlockSlider } from '../../modules/widgets/conclusionBlockSlider';
import { initPhoneSlider } from '../../modules/widgets/PhoneScreenSlider';
import { initSwiper } from '../../modules/swiper';
import { retryUntil } from '../../util/retry';

const initHomepageSwiperSliders = (): void => {
  initSwiper('#decoded-testimonials .swiper-container', {
    navigation: {
      nextEl: '#decoded-testimonials .swiper-button-next',
      prevEl: '#decoded-testimonials .swiper-button-prev',
    },
  });
  initSwiper('#decoded-media-slider .swiper-container', {
    centeredSlides: true,
    spaceBetween: 0,
    breakpoints: {},
  });
};

export function setupHomePageSwiperSlider(): void {
  if (typeof window.Swiper !== 'undefined') {
    initHomepageSwiperSliders();
    initPhoneSlider();
    initConclusionBlockSlider();
  } else {
    const swiperScriptTag = document.querySelector<HTMLScriptElement>('#swiper-js');
    swiperScriptTag?.addEventListener('load', () => {
      const isSwiperDefined = typeof window.Swiper !== 'undefined';

      retryUntil(isSwiperDefined, () => {
        initHomepageSwiperSliders();
        initPhoneSlider();
        initConclusionBlockSlider();
      });
    });
  }
}
