import { FunctionPropertyNames } from './typeHelpers';

export abstract class DOMRouteHandler {
  static async initAsync(): Promise<void> {
    return;
  }

  static init(): void {
    return;
  }

  static finalize(): void {
    return;
  }
}

/**
 * DOM-based Routing
 *
 * Based on {@link http://goo.gl/EUTi53|Markup-based Unobtrusive Comprehensive DOM-ready Execution} by Paul Irish
 *
 * The routing fires all common scripts, followed by the page specific scripts.
 * Add additional events for more control over timing e.g. a finalize event
 */
type Routes = Record<
  string,
  {
    initAsync: () => Promise<void>;
    init: () => void;
    finalize: () => void;
  }
>;

export class Router {
  public readonly routes: Routes;

  /**
   * Create a new Router
   * @param {Object} routes
   */
  constructor(routes: Routes) {
    this.routes = routes;
  }

  /**
   * Fire Router events
   * @param {string} route DOM-based route derived from body classes (`<body class="...">`)
   * @param {string} [event] Events on the route. By default, `init` and `finalize` events are called.
   */
  fire<K extends keyof Routes>(route: K, event: FunctionPropertyNames<Routes[K]>): void {
    document.dispatchEvent(
      new CustomEvent('routed', {
        bubbles: true,
        cancelable: true,
        detail: {
          route,
          fn: event,
        },
      })
    );

    const canFire = route !== '' && this.routes[route] && typeof this.routes[route][event] === 'function';
    if (canFire) {
      // @fixme no-floating-promises was ignored here when the rule was introduced
      // in order to avoid a refactor while introducing the new rule.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.routes[route][event]();
    }
  }

  /**
   * Automatically load and fire Router events
   *
   * Events are fired in the following order:
   *  * common init
   *  * page-specific init
   *  * page-specific finalize
   *  * common finalize
   */
  loadEvents(): void {
    // Fire common init JS
    this.fire('Common', 'init');
    this.fire('Common', 'initAsync');

    // Fire page-specific init JS, and then finalize JS
    document.body.className
      .toLowerCase()
      .replace(/-/g, '_')
      .split(/\s+/)
      .map((str) => {
        // convert CSS class names to PascalCase
        return `${str
          .replace(/[\W_]/g, '|')
          .split('|')
          .map((part) => `${part.charAt(0).toUpperCase()}${part.slice(1)}`)
          .join('')}`;
      })
      .forEach((className) => {
        this.fire(className, 'initAsync');
        this.fire(className, 'init');
        this.fire(className, 'finalize');
      });

    // Fire common finalize JS
    this.fire('Common', 'finalize');
  }
}
