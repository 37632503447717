type OnNavigationFunction = (e: any, page: number, perPage?: number) => void;

export const DEFAULT_PER_PAGE = 24;

export function renderPaginationUI(
  root: HTMLElement | null,
  onNavigation?: OnNavigationFunction,
  currentPage = 1,
  perPage = DEFAULT_PER_PAGE,
  totalItems = 1
): void {
  if (!root) {
    return;
  }

  const container = document.createElement('div');
  container.classList.add('numbered-pagination', 'text-center');

  const totalPages = Math.ceil(totalItems / perPage);
  if (totalPages <= 1) {
    root.innerHTML = '';
    return;
  }

  if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  const createPageLink = (pageNumber: number, text: string, isCurrent: boolean): HTMLElement => {
    const link = document.createElement('a');
    link.href = 'javascript:;';
    link.classList.add('page-numbers');
    if (isCurrent) {
      const link = document.createElement('span');
      link.innerHTML = text;
      link.classList.add('current', 'page-numbers');
      link.setAttribute('aria-current', 'page');
      return link;
    } else {
      link.innerHTML = text;
    }
    link.addEventListener('click', (event) => {
      event.preventDefault();
      onNavigation && onNavigation(event, pageNumber, perPage);
    });
    return link;
  };

  if (currentPage > 1) {
    container.appendChild(
      createPageLink(
        currentPage - 1,
        '<svg aria-label="Previous page" width="23" height="9" viewBox="0 0 23 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.44785 5.50003L6.16383 7.02535C6.57661 7.39227 6.61379 8.02434 6.24688 8.43713C5.87996 8.84991 5.24788 8.88709 4.8351 8.52017L1.16463 5.25753C1.14611 5.24152 1.12806 5.22473 1.11052 5.20716C1.02842 5.12522 0.963374 5.03206 0.915637 4.93235C0.850023 4.79562 0.817584 4.64764 0.817638 4.50003C0.817729 4.24272 0.916562 3.98651 1.11052 3.79291C1.12806 3.77533 1.14612 3.75853 1.16465 3.74252L4.8351 0.479896C5.24788 0.112978 5.87996 0.150159 6.24687 0.562942C6.61379 0.975725 6.57661 1.6078 6.16383 1.97472L4.44785 3.50003L21.0449 3.50003C21.5972 3.50003 22.0449 3.94775 22.0449 4.50003C22.0449 5.05232 21.5972 5.50003 21.0449 5.50003L4.44785 5.50003Z" fill="#0A0A0A"></path></svg>',
        false
      )
    );
  }

  const pageLinksContainer = document.createElement('span');
  pageLinksContainer.classList.add('page-links');
  pageLinksContainer.setAttribute('role', 'region');
  pageLinksContainer.setAttribute('aria-label', 'pagination links');

  let startPage: number;
  let endPage: number;

  if (totalPages <= 3) {
    startPage = 1;
    endPage = totalPages;
  } else if (currentPage <= 2) {
    startPage = 1;
    endPage = 3;
  } else if (currentPage >= totalPages - 1) {
    startPage = totalPages - 2;
    endPage = totalPages;
  } else {
    startPage = currentPage - 1;
    endPage = currentPage + 1;
  }

  for (let i = startPage; i <= endPage; i++) {
    const isCurrent = i === currentPage;
    const pageLink = createPageLink(i, i.toString(), isCurrent);
    pageLinksContainer.appendChild(pageLink);
  }

  if (startPage > 1) {
    const ellipsis = document.createElement('span');
    ellipsis.textContent = '…';
    pageLinksContainer.insertBefore(ellipsis, pageLinksContainer.firstChild);
  }

  if (endPage < totalPages) {
    const ellipsis = document.createElement('span');
    ellipsis.textContent = '…';
    pageLinksContainer.appendChild(ellipsis);
  }

  container.appendChild(pageLinksContainer);

  if (currentPage < totalPages) {
    container.appendChild(
      createPageLink(
        currentPage + 1,
        '<svg aria-label="Next page" xmlns="http://www.w3.org/2000/svg" width="26" height="11" viewBox="0 0 26 11" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.3698 4.49999L19.8356 2.2474C19.4229 1.88048 19.3857 1.24841 19.7526 0.835626C20.1195 0.422843 20.7516 0.385662 21.1644 0.752581L25.653 4.7425C25.6715 4.7585 25.6896 4.77529 25.7071 4.79286C25.7892 4.87481 25.8543 4.96797 25.902 5.06768C25.9676 5.20441 26.0001 5.35238 26 5.49999C25.9999 5.75731 25.9011 6.01351 25.7071 6.20712C25.6896 6.22469 25.6715 6.24149 25.653 6.2575L21.1644 10.2474C20.7516 10.6143 20.1195 10.5771 19.7526 10.1644C19.3857 9.75157 19.4229 9.1195 19.8356 8.75258L22.3698 6.49999L1.5 6.49999C0.947716 6.49999 0.5 6.05227 0.5 5.49999C0.5 4.9477 0.947716 4.49999 1.5 4.49999L22.3698 4.49999Z"></path></svg>',
        false
      )
    );
  }

  root.innerHTML = '';
  root.appendChild(container);
}
