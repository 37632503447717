import * as routes from './routes';

import { Router } from './util/Router';

// The typed package does not contain the identify method.
interface Heap {
  identify(identity: string): void;
}

declare global {
  interface Window {
    EmbarkDOMRouter: Router;
    jQuery: JQueryStatic;
    heap?: Heap;
    _iaq?: any[];
    maybeUserEmail?: string;
    Sentry?: {
      captureException: (error: any) => void;
    };
  }
}

const router = new Router(routes);
// allow partials to subscribe & emit route events using this global variable
window.EmbarkDOMRouter = router;

jQuery(document).ready(function () {
  router.loadEvents();
});
