function toggleTraitDescription(item: HTMLElement): void {
  const target = item.getAttribute('aria-controls');
  if (!target) {
    return;
  }

  const targetEl = document.getElementById(target);
  if (!targetEl) {
    return;
  }

  const nextState = item.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';
  item.setAttribute('aria-expanded', nextState);
  item.innerHTML = `${nextState === 'true' ? 'Show less' : 'Show more'}`;
  targetEl.classList.toggle('expanded');
  targetEl.focus();
}

export function initTraitDescriptionAccordion(): void {
  document.querySelectorAll('.database-traits .database-item__actions [aria-controls]').forEach((item) => {
    if (!(item instanceof HTMLElement)) {
      return;
    }

    item.addEventListener('click', (e) => {
      e.preventDefault();
      toggleTraitDescription(item);
    });
  });
}
