import { Swiper } from 'src/types/swiper';

export function initConclusionBlockSlider(): void {
  const breakpoint = window.matchMedia('(max-width:640px)');
  const SWIPER_CONTAINER_CLASS = '.conclusion-block-mobile-swiper .swiper';

  let swiperInstance: Swiper;

  const initMobileSlider = (): void => {
    if (!window.Swiper) {
      return;
    }

    setSlideHeights(SWIPER_CONTAINER_CLASS);

    swiperInstance = new window.Swiper(SWIPER_CONTAINER_CLASS, {
      a11y: {
        enabled: true,
        prevSlideMessage: 'Previous product',
        nextSlideMessage: 'Next product',
      },
      keyboardControl: true,
      grabCursor: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      centeredSlides: true,
      slidesPerView: 'auto',
    });
  };

  const breakpointChecker = (): void => {
    if (breakpoint.matches) {
      addOrRemoveDesktopClasses(SWIPER_CONTAINER_CLASS);
      initMobileSlider();
    }

    if (!breakpoint.matches && swiperInstance !== undefined) {
      swiperInstance.destroy(true, true);
      addOrRemoveDesktopClasses(SWIPER_CONTAINER_CLASS);
      unsetFixedSlideHeights(SWIPER_CONTAINER_CLASS);
      removePaginationBullets(SWIPER_CONTAINER_CLASS);
    }
  };

  if (breakpoint?.addEventListener) {
    breakpoint.addEventListener('change', breakpointChecker);
  } else {
    breakpoint.addListener(breakpointChecker);
  }
  breakpointChecker();
}

function setSlideHeights(containerClasses: string): void {
  const slides = document.getElementsByClassName('mobile-swiper-slide') as HTMLCollectionOf<HTMLElement>;
  let maxHeight = 0;
  const slidesCount = slides.length;
  for (let i = 0; i < slidesCount; i++) {
    const stringHeight = window.getComputedStyle(slides[i]).height;
    if (null !== stringHeight) {
      const slideHeight = parseInt(stringHeight, 10);
      maxHeight = Math.max(maxHeight, slideHeight);
    }
  }
  const slideInners = document.querySelectorAll(containerClasses + ' .column-rounded-shadows');
  slideInners.forEach((slideInner) => {
    if (slideInner instanceof HTMLElement) {
      const heightMinusMargins = maxHeight - 48;
      slideInner.style.height = heightMinusMargins + 'px';
    }
  });
}

function unsetFixedSlideHeights(containerClasses: string): void {
  const slideInners = document.querySelectorAll(containerClasses + ' .column-rounded-shadows');
  slideInners.forEach((slideInner) => {
    if (slideInner instanceof HTMLElement) {
      slideInner.style.height = '100%';
    }
  });
}

function removePaginationBullets(containerClasses: string): void {
  const swiperPagination = document.querySelector(containerClasses + ' .swiper-pagination');
  if (!swiperPagination) {
    return;
  }
  swiperPagination.innerHTML = '';
}

function addOrRemoveDesktopClasses(containerClasses: string): void {
  const swiperWrapper = document.querySelector(containerClasses + ' #mobile-swiper-wrapper');
  const swiperSlides = document.querySelectorAll(containerClasses + ' .mobile-swiper-slide');
  if (!swiperWrapper || !swiperSlides.length) {
    return;
  }
  // toggles classes between foundation and swiper classes
  swiperWrapper.classList.toggle('grid-x');
  swiperWrapper.classList.toggle('swiper-wrapper');
  swiperSlides.forEach((slide) => {
    slide.classList.toggle('medium-4');
    slide.classList.toggle('swiper-slide');
  });
}
